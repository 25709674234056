@import '../../../styles/react-media-queries.scss';
@import '../../../styles/main.scss';

.socialImpactEmailContainer {
  @media #{$very-wide-layout} {
    @include max-width-two-thirds;
  }
  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .socialImpactEmailClipboard {
    /* stylelint-disable-next-line unit-disallowed-list */
    max-height: 221px;
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: pre-wrap;
  }

  /* stylelint-disable-next-line max-nesting-depth */
  button {
    @media #{$mobile-only} {
      @include width-100;
    }
  }
}
